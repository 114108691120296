import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getDoctorCase, getSessionA} from "../../services";
import {message} from "antd";
import {setLoading} from "../../store/appSlice";

export default function SessionsNav(props: any) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const caseId: string = searchParams.get('case_id') || "";
    const dispatch = useDispatch();

    useEffect(() => {
        init().then()
    }, []);

    const init = async () => {
        dispatch(setLoading(true));
        const result = await getDoctorCase(caseId);
        dispatch(setLoading(false));
        if (result.status === 200) {
            if (result?.data?.data?.current_form === 'formB') {
                navigate(`/epac/session-b?case_id=${caseId}`, {
                    replace: true
                });
                return;
            }
            if (result?.data?.data?.current_form === 'formC') {
                navigate(`/epac/session-c?case_id=${caseId}`, {
                    replace: true
                });
                return;
            }
            navigate(`/epac/session-a?case_id=${caseId}`, {
                replace: true
            });
        } else {
            message.error("You don't have permission to access this case", 999999);
        }
    }

    return null;
}