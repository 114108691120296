import Header from "components/Header";
import { useState } from "react";
import { Container } from "../home/styles";
import SpaceView from "../../components/Space";
import HomeTable from "../home/HomeTable";
import HomeTableController from "../home/HomeTableController";
import { SocketWrapper } from "components/SocketContext";

export default function HomeScreen(props: any) {
    const [statusFilter, setStatusFilter] = useState("");
    const [sortDesc, setSortDesc] = useState("");
    const [sortAsc, setSortAsc] = useState("");
    const [keyword, setKeyword] = useState("");

    const handleActionSearch = (value: string) => {
        setKeyword(value);
    };

    const handleActionFilter = (filter: any) => {
        setSortAsc(filter?.sortAsc);
        setSortDesc(filter?.sortDesc);
        setStatusFilter(filter?.claim_status);
    };

    return (
        <SocketWrapper>
            <Header />
            <Container>
                <SpaceView size={32} />
                <HomeTableController
                    handleActionSearch={handleActionSearch}
                    handleActionFilter={handleActionFilter}
                ></HomeTableController>
                <SpaceView size={20} />
                <HomeTable
                    sortAsc={sortAsc}
                    sortDesc={sortDesc}
                    keyword={keyword}
                    claimStatus={statusFilter}
                ></HomeTable>
                <SpaceView size={32} />
            </Container>
        </SocketWrapper>
    );
}
