import React, {useState} from "react";
import {StyledInput} from "./styles";
import SearchIcon from "../../../../assets/images/search.png";
import SubmitSearchButton from "../SubmitSearchButton";

type Props = {
    handleSearch?: any,
    handleChange?: any
};

export default function InputSearch(props: any) {
    const { handleSearch, handleChange, placeholder, widthInput } = props;
    const [keyword, setKeyword] = useState("");
    
    const handleClick = () => {
        handleSearch();
    }

    return (
        <>
            <StyledInput
                size="large"
                placeholder={placeholder ?? "Search"}
                value={keyword || ""}
                onChange={(event) => {
                    handleChange(event.target.value);
                    setKeyword(event.target.value);
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        handleSearch();
                    }
                }}
                style={{ width: `${widthInput ?? 300}px` }}
                prefix={<img src={SearchIcon} alt={""} style={{width: '14px'}}/>}/>
            <SubmitSearchButton handleSearch={handleClick}></SubmitSearchButton>
        </>
    )
}
