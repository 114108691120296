import React, {useEffect, useState} from "react";
import {StyledContainer, StyledControlContainer, StyledDrawer} from "./styles";
import {LabelRegularProps} from "../../../components/Form/TextInput/styles";
import CustomFilter from "./CustomFilter";
import InputSearch from "./InputSearch";
import {useDispatch, useSelector} from "react-redux";
import {selectTotalCase} from "../../../store/appSlice";
import {
    Button,
    Card,
    Col,
    Row,
    Form,
    Select,
} from "antd";
import {FILTER_CLAIM_STATUS, FILTER_CLAIM_STATUS_ARCHIVE} from "../../../constants";
import { useLocation } from "react-router-dom";

type Props = {
    handleActionSearch?: any,
    handleActionFilter?: any
};

export default function HomeTableController(props: any) {
    const {handleActionSearch, handleActionFilter} = props;
    const totalCase = useSelector(selectTotalCase);
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const [fieldSort, setFieldSort] = useState("createdAt");
    const [defaultOrder, setDefaultOrder] = useState("");
    const [orderData, setOrderData] = useState("sortDate");
    const [sortDesc, setSortDesc] = useState('createdAt');
    const [sortAsc, setSortAsc] = useState('');
    const [keyword, setKeyword] = useState("");
    const sortDate = ['createdAt', 'date_of_submission', 'date_of_admission'];
    const {pathname} = useLocation();

    const handleShowFilter = () => {
        setVisibleFilter(true);
    };

    const handleCloseFilter = () => {
        setVisibleFilter(false);
    };

    const handleSelectStatus = (val: string) => {
        setStatusFilter(val);
    };

    const handleSelectField = (val: string) => {
        if(sortDate.includes(val)) {
            setOrderData('sortDate');
            if(sortDesc) {
                setSortDesc(val);
            } else {
                setSortAsc(val);
            }
        } else {
            setOrderData('sortAmount');
            setSortDesc('');
            setSortAsc(val);
        }
        setFieldSort(val);
    };

    const handleSelectOrderDate = (val: string) => {
        if(val === 'desc') {
            setSortDesc(fieldSort);
        } else {
            setSortAsc(fieldSort);
        }
    };

    useEffect(() => {
        if(sortDesc) setSortAsc('');
    }, [sortDesc])

    useEffect(() => {
        if(sortAsc) setSortDesc('');
    }, [sortAsc])
    
    useEffect(() => {
        if(orderData == 'sortDate') {
            setSortAsc('');
        } else {
            setSortDesc(fieldSort);
        }
    }, [orderData])

    const handleSelectOrderAmount = (val: string) => {
        if(val === 'desc') {
            setSortDesc(fieldSort);
        } else {
            setSortAsc(fieldSort);
        }
    };

    const handleFilterCase = () => {
        setVisibleFilter(false);
        handleActionFilter({
            'sortDesc': sortDesc,
            'sortAsc': sortAsc,
            'claim_status': statusFilter,
        });
    };

    const handleSearch = () => {
        handleActionSearch(keyword);
    };

    const handleChangeKeyWord = (value: string) => {
        setKeyword(value);
    };
    
    return (
        <StyledContainer>
            <LabelRegularProps theme={{color: '#22313F', fontWeight: '600', fontSize: '16px', lineHeight: '24px'}}>All
                Cases ({totalCase})</LabelRegularProps>
            <StyledControlContainer>
                <CustomFilter handleClick={handleShowFilter}></CustomFilter>
                <InputSearch handleSearch={handleSearch} handleChange={handleChangeKeyWord}></InputSearch>
            </StyledControlContainer>
            <StyledDrawer
                title="Filter cases"
                placement={"right"}
                width={350}
                onClose={handleCloseFilter}
                visible={visibleFilter}
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name="status" label="Status">
                                <Select defaultValue="All" onSelect={handleSelectStatus}>
                                    <Select.Option value="" selected>
                                        All
                                    </Select.Option>
                                    
                                    { pathname === "/archive" ? FILTER_CLAIM_STATUS_ARCHIVE.map((status: any, index: number) => (
                                        <Select.Option key={index} value={status?.value}>
                                            {status?.text}
                                        </Select.Option>
                                        )) : FILTER_CLAIM_STATUS.map((status: any, index: number) => (
                                        <Select.Option key={index} value={status?.value}>
                                            {status?.text}
                                        </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="field"
                                label="Sorting"
                                className="mb-2"
                            >
                                <Select defaultValue="Submission date" onSelect={handleSelectField}>
                                    <Select.Option value="date_of_submission" selected>Submission date</Select.Option>
                                    <Select.Option value="date_of_admission">Admission date</Select.Option>
                                    <Select.Option value="total_amount">PAC amount</Select.Option>
                                </Select>
                            </Form.Item>

                            {orderData === "sortDate" ?
                                <Form.Item
                                    name="orderDate"
                                    label=""
                                >
                                    <Select defaultValue="Newest to oldest" onSelect={handleSelectOrderDate}>
                                        <Select.Option value="desc">Newest to oldest</Select.Option>
                                        <Select.Option value="asc">Oldest to newest</Select.Option>
                                    </Select>
                                </Form.Item>
                                :
                                <Form.Item
                                    name="orderAmount"
                                    label=""
                                >
                                    <Select defaultValue="Least to greatest" onSelect={handleSelectOrderAmount}>
                                        <Select.Option value="asc">Least to greatest</Select.Option>
                                        <Select.Option value="desc">Greatest to least</Select.Option>
                                    </Select>
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                </Form>
                <Row className="mt-5 action" gutter={24}>
                    <Col span={12} className={"text-center"}>
                        <Button
                            className="btn-filter-cancel w-full"
                            onClick={handleCloseFilter}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12} className={"text-center"}>
                        <Button
                            type="primary"
                            className="btn-filter-apply w-full"
                            onClick={handleFilterCase}
                        >
                            Apply
                        </Button>
                    </Col>
                </Row>
            </StyledDrawer>
        </StyledContainer>
    )
}
