import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./index";

interface AppStateType {
  loading: boolean,
  totalCase: number,
}

const initialState: AppStateType = {
  loading: false, 
  totalCase: 0
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTotalCase: (state, action) => {
      state.totalCase = action.payload;
    },
  },
});

export const {setLoading, setTotalCase} = appSlice.actions;

export const selectLoading = (state: RootState) => state?.app?.loading;
export const selectTotalCase = (state: RootState) => state?.app?.totalCase;

export default appSlice.reducer;