import React from "react";
import styled from "styled-components";
import {
    Drawer,
} from "antd";

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledControlContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`

export const StyledPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    height: 242px;
    border-radius: 16px;
    flex-grow: 1;
    margin: 0px 24px;
    padding: 36px;
    background: ${props => props.theme.background || '#FFF0E6'};
`

export const StyledDrawer = styled(Drawer)`
    .ant-form-item-label {
        font-size: 18px;
        font-weight: 600;
    }

    .btn-filter-cancel {
        background-color: #fff;
        border-radius: 16px;

        &:hover {
            color: #24d6e3;
            border-color: #24d6e3;
        }
    }

    .btn-filter-apply {
        background-color: #00c1d5;
        border-radius: 16px;

        &:hover {
            background-color: #24d6e3;
            border-color: #24d6e3;
            color: #fff;
        }
    }

    .ant-form-item {
        margin-bottom: 24px !important;
    }

    .ant-form-item-control-input {
        align-items: center;
        display: flex;
        min-height: 40px;
        position: relative;

        .ant-select-selector {
            height: 40px;
            padding: 5px 16px;
            border-radius: 16px;
        }
    }
    .action {
        .ant-col-12 {
            text-align: center;
            button {
                font-size: 14px;
                font-weight: 400;
                height: 40px;
                line-height: 1.5715;
                padding: 8px 23px;
                width: 100%;
            }
        }
    }
`;
