import {useContext, useEffect, useState} from "react";
import {
    Account,
    AccountIcon,
    AccountText,
    Container,
    Logo,
    Menus,
    StyledNotification,
    MenuPopupBackground,
    MenuPopup,
    MenuPopupItem,
} from "./styles";
import { Notification } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserSelector, setUserInfo } from "../../pages/auth/authSlice";
import AvatarIcon from "../../assets/images/avt.png";
import LogoIcon from "../../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {setLoading, setTotalCase} from "../../store/appSlice";
import { Col, Row, Menu } from 'antd';
import { UserIcon } from "components/icons/UserIcon";
import { LogoutIcon } from "components/icons/LogoutIcon";
import { DotIcon } from "components/icons/DotIcon";
import {getProviderFeed, updateProviderFeed, SERVICES} from "../../services";
import moment from "moment";
import {CLAIM_STATUS_COLOR, CLAIM_STATUS_VALUE_MAP_TEXT, PAGINATION} from "../../constants";
import {StyledStatus} from "../../pages/home/HomeTable/styles";
import { SocketContext } from "components/SocketContext";

export default function Header(props: any) {
    const user: any = useSelector(selectUserSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [notifies, setNotifies] = useState<any[]>([]);
    const [countFeed, setCountFeed] = useState<any>(null);
    const [limit, setLimit] = useState(PAGINATION.LIMIT_6);
    const [totalFeed, setTotalFeed] = useState(0);

    // Get notification list at initial
    useEffect(() => {
        handleGetFeedData(PAGINATION.LIMIT_6, 1);
    }, [])

    const websocket = useContext(SocketContext);

    useEffect(() => {
        websocket.onmessage = (e: MessageEvent) => {
            try {
                const { action, data } = JSON.parse(e.data);
                const feedRecords = JSON.parse(data);
                const currentUserNewNotification = feedRecords?.find((item: any) => item.doctor_id === user.id && !item.assessor_id);
                const storedNotificationId = localStorage.getItem('newNotificationId');
                if (!currentUserNewNotification || storedNotificationId === currentUserNewNotification?.id) {
                    return;
                }
                localStorage.setItem('newNotificationId', currentUserNewNotification.id);
                switch (action) {
                    case "newFeed":
                        handleGetFeedData(PAGINATION.LIMIT_6, 1);
                        break;
                    default:
                        break;
                }
            } catch (error) {
            }
        };
    }, []);

    // handle fetching data feed
    const handleGetFeedData = async (limit: number, page: number = 1) => {
        setLoading(true);
        setLimit(limit);
        try {
            const {data} = await getProviderFeed(limit, page);
            setNotifies(data?.data?.items);
            if(data?.data?.unread >= 99) {
                setCountFeed('99+');
            } else {
                setCountFeed(data?.data?.unread);
            }
            setTotalFeed(data?.data?.page_info?.total)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }
    
    // handle update is_read norify
    const handleReadNotify = async (feedId: string) => {
        try {
            const {data} = await updateProviderFeed({
                feed_id: feedId,
                is_read: true
            });
            console.log(data);
            if(data?.code == 200) {
                setTimeout(() => {
                    handleGetFeedData(limit, 1);
                }, 1000);
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    // handle click read notify
    const handleClickNotify = (caseId: string, feedId: string, isRead: boolean) => {
        if(!isRead) handleReadNotify(feedId);
        navigate(`/epac/session-a?case_id=${caseId}`);
    }
    
    // handle click read more notify
    const handleReadMoreNotify = () => {
        let paramLimit: number = limit + PAGINATION.LIMIT_10;
        handleGetFeedData(paramLimit, 1);
    }

    const onLogout = () => {
        dispatch(setUserInfo(null));
        navigate("/");
    };

    const onProfile = () => {
        navigate("/profile");
    };

    return (
        <Container>
            <Logo onClick={() => navigate("/home")} src={LogoIcon} />

            <Menus>
                <NavLink
                    to="/announcement"
                    className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                    }
                >
                    Home
                </NavLink>
                <NavLink
                    to="/home"
                    className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                    }
                >
                    All Cases
                </NavLink>
                <NavLink
                    to="/epac/verify"
                    className={({ isActive }) =>
                        isActive || props.isEpac ? "active" : "inactive"
                    }
                >
                    Request PAC
                </NavLink>
                <NavLink
                    to="/archive"
                    className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                    }
                >
                    Archived
                </NavLink>
            </Menus>
            
            <StyledNotification>
                <Menu mode="horizontal">
                    <Menu.SubMenu key="SubMenu" icon={<Notification />} className={countFeed?'menu-notify' : 'not-un-read'} title={countFeed?countFeed : '0'}>
                        {notifies?.length > 0 && notifies.map((item: any, key) => (
                            <Menu.Item key={key} 
                                       className={!item?.is_read ? 'notify-new-read' : ''} 
                                       onClick={() => handleClickNotify(item?.case_id, item?.id, item?.is_read)}>
                                <Row gutter={24}>
                                    <Col span={15} className={'notify-case-no'}>Case no. {item?.case_serial}</Col>
                                    <Col span={9} className={'notify-text-right notify-time'}>
                                        {moment(item.createdAt).format("DD-MMM-YYYY HH:mm")}
                                    </Col>
                                    <Col span={22} className={'notify-status'}>
                                        <StyledStatus theme={{
                                            color: '#2AC3FF',
                                            fontWeight: '400',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            background: '#E4F3FC'
                                        }} className={CLAIM_STATUS_COLOR[item?.case_claim_status]}>
                                            {CLAIM_STATUS_VALUE_MAP_TEXT[item?.case_claim_status]}
                                        </StyledStatus>
                                    </Col>
                                    <Col span={2} className={'notify-text-right notify-new'}>
                                        {!item?.is_read && <DotIcon />}
                                    </Col>
                                </Row>
                            </Menu.Item>
                        ))}
                        {notifies?.length > 0 
                            && notifies?.length < totalFeed
                            && <Menu.Item 
                                className={'show-more'}>
                                <div onClick={(event) => {
                                    event.stopPropagation();
                                    handleReadMoreNotify();
                                }} >
                                    Show more...
                                </div>
                            </Menu.Item>
                        }
                    </Menu.SubMenu>
                </Menu>
                <Account>
                    <AccountText>
                        Hi! {user?.full_name}
                        <br/>
                        {user?.clinic_name ? 
                            <div style={{opacity: 0.7, fontWeight: 500}}>
                            {user?.clinic_name} - {user?.clinic_code}</div> 
                        : null}
                    </AccountText>
                    <AccountIcon src={user?.attributes?.picture || AvatarIcon} />
                    <MenuPopupBackground>
                        <MenuPopup>
                            <MenuPopupItem onClick={onProfile}>
                                <UserIcon />
                                <span>Profile</span>
                            </MenuPopupItem>
                            <MenuPopupItem onClick={onLogout}>
                                <LogoutIcon />
                                <span>Logout</span>
                            </MenuPopupItem>
                        </MenuPopup>
                    </MenuPopupBackground>
                </Account>
            </StyledNotification>
        </Container>
    );
}
