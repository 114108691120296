import { Auth, Hub } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { SERVICES } from "../services";
import React from "react";

type Proxy = {
  onmessage: WebSocket['onmessage']
  send(..._: Parameters<WebSocket['send']>): Promise<void>
}

export const SocketContext = React.createContext<Proxy>({} as any);

export const SocketWrapper: React.FC = props => {
  const proxy = React.useRef<Proxy>();

  React.useMemo(() => { // useEffect does not guarantee calling before render
    let setPending: (_: WebSocket) => void;
    let socket: WebSocket | Promise<WebSocket> = new Promise(
      resolve => setPending = resolve
    );

    // useMemo does not guarantee call exactly once with no dependency,
    if (proxy.current) return;  // convoluted APIs much, React?
  
    proxy.current = { // allow send or onmessage to be called before socket is connected
      set onmessage(listener: WebSocket['onmessage']) {
        if (!(socket as Promise<WebSocket>).then?.(
          connected => connected.onmessage = listener)
        ) (socket as WebSocket).onmessage = listener; 
      },
      async send(...args) {
        (await socket).send(...args);
      }
    }

    if (!SERVICES.WEBSOCKET) return; 

    const connnect = async () => {
      const session = await Auth
        .currentSession()
        .catch(noUser => {
          console.log(noUser)
          return new Promise<CognitoUserSession>(resolve =>
            Hub.listen('auth', (capsule: { payload: { event: string, data?: any }}) => {
              console.log(capsule)
              const { data, event } = capsule.payload
              if (data && event == 'signIn') resolve(data.signInUserSession)
            })
          )
        })
      
      setPending(socket = new WebSocket(
        `${SERVICES.WEBSOCKET}?token=${session
          .getAccessToken()
          .getJwtToken()
        }`
      ))
    }
    
    connnect();
  }, [])

  return <SocketContext.Provider
    value={proxy.current as Proxy}
    {...props }
  />
}