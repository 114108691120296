import Loading from "../components/Loading";
import React, { Suspense, useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import HomeScreen from "../pages/home";
import ArchiveScreen from "../pages/archive";
import { useSelector } from "react-redux";
import { selectUserSelector } from "../pages/auth/authSlice";
import { selectLoading } from "../store/appSlice";
import SessionsNav from "../pages/epac/sessions";
import { useNetworkState } from "react-use";
import { notification } from "antd";
import AnnouncementScreen from "pages/announcement";
import { SocketWrapper } from "components/SocketContext";

const SiginUpProfile = React.lazy(() => import("../pages/auth/SiginUpProfile"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const SessionC = React.lazy(() => import("../pages/epac/sessionC"));
const SessionB = React.lazy(() => import("../pages/epac/sessionB"));
const SessionA = React.lazy(() => import("../pages/epac/sessionA"));
const EpacVerify = React.lazy(() => import("../pages/epac/verify"));
const ForgotPassword = React.lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const SignUp = React.lazy(() => import("../pages/auth/SignUp"));
const Profile = React.lazy(() => import("../pages/auth/Profile"));

let PREV_URL = window.location.href.replace(origin, '');

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function AppRouter() {
    const user = useSelector(selectUserSelector);
    const loading = useSelector(selectLoading);
    const { online } = useNetworkState();
    const [lostConnection, setLostConnection] = useState(false);

    useEffect(() => {
        if (!online) {
            setLostConnection(true);
            notification.error({
                message: "Lost Connection",
                description:
                    "Your internet session was interrupted. We'll try to reconnect you in a few seconds",
            });
        }
        if (online && lostConnection) {
            window.location.reload();
        }
    }, [online]);
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <ScrollToTop />
                {user?.id ? (
                    !user?.is_first_login ?
                    <Routes>
                        <Route path="/announcement" element={<AnnouncementScreen />} />
                        <Route path="/home" element={<HomeScreen/>}/>
                        <Route path="/archive" element={<ArchiveScreen/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/epac" element={<SessionsNav/>}/>
                        <Route path="/epac/verify" element={<EpacVerify/>}/>
                        <Route path="/epac/session-a" element={
                            <SocketWrapper><SessionA/></SocketWrapper>
                        }/>
                        <Route path="/epac/session-b" element={
                            <SocketWrapper><SessionB/></SocketWrapper>
                        }/>
                        <Route path="/epac/session-c" element={<SessionC/>}/>
                        <Route path="/*" element={<Navigate replace to="/announcement" />} />
                    </Routes> :
                    <Routes>
                        <Route path="/signup-info" element={<SiginUpProfile/>}/>
                        <Route path="/*" element={<Navigate replace to="/signup-info" state="/announcement" />} />
                    </Routes>
                ) : (
                    <Routes>
                        <Route path="/login"
                               element={<Login redirectUrl={PREV_URL.includes('epac') ? PREV_URL : null}/>}/>
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/reset-password" element={<ResetPassword/>}/>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/*" element={<Navigate replace to="/login"/>}/>
                    </Routes>
                )}
            </Router>
            {loading || lostConnection ? <Loading/> : null}
        </Suspense>
    );
}

export default AppRouter;
