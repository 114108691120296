import React from "react";
import {StyledButton} from "./styles";

type Props = {
    handleSearch?: any
};

export default function SubmitSearchButton(props: any) {
    const {handleSearch} = props;
    return (
        <StyledButton onClick={handleSearch}>Search</StyledButton>
    )
}
