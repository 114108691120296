import React from "react";

type Props = {};

export const DotIcon = (props: Props) => {
    return (
        <svg className={'inline-block'} fill={'#01c1d5'} xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12">
            <circle r="4" cx="6" cy="6" />
        </svg>
    );
};
