import React from "react";

type Props = {};

export const LogoutIcon = (props: Props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.4399 14.62L19.9999 12.06L17.4399 9.5"
                stroke="#F04646"
            />
            <path d="M9.75977 12.0605H19.9298" stroke="#F04646" />
            <path
                d="M11.7598 20C7.33977 20 3.75977 17 3.75977 12C3.75977 7 7.33977 4 11.7598 4"
                stroke="#F04646"
            />
        </svg>
    );
};
