import React from "react";
import FilterIcon from "../../../../assets/images/filter.png";
import {LabelRegularProps} from "../../../../components/Form/TextInput/styles";
import {StyledButton} from "./styles";

type Props = {
    handleClick?: any
};

export default function CustomFilter(props: any) {
    const {handleClick} = props;
    return (
        <StyledButton onClick={handleClick} icon={<img src={FilterIcon} alt=""/>}>
            <LabelRegularProps theme={{color: '#2A2F36', fontWeight: '400', fontSize: '14px', lineHeight: '20px'}}>Filter</LabelRegularProps>
        </StyledButton>
    )
}
