import React from "react";
import styled from "styled-components";
import {Button} from "antd";

export const StyledButton = styled(Button)`
    padding: 0px 12px;
    width: 127.06px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #00C1D5;
    border-radius: 50px;
    
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #03B4CE;
`
