import React from "react";
import styled from "styled-components";
import {Input} from "antd";

export const StyledInput = styled(Input)`
    width: 300px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E9EEF7;
    border-radius: 16px;
`
