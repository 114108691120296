import Header from "components/Header";
import { useEffect, useState } from "react";
import SpaceView from "../../components/Space";
import { Container, Table, TableHeader, TableRow, Title, StyledModal } from "./styles";
import InputSearch from "pages/home/HomeTableController/InputSearch";
import Pagination, { PaginationConfig } from "components/Common/Pagination";
import { PAGINATION } from "../../constants";
import { StyledControlContainer } from "pages/home/HomeTableController/styles";
import { Button, Empty } from "antd";
import newIcon from "../../assets/images/new.svg";
import readIcon from "../../assets/images/read.svg";
import unreadIcon from "../../assets/images/unread.svg";
import fileIcon from "../../assets/images/file.svg";
import { getListAnnouncement, updateProviderAnnouncement } from "services";
import { formatDate } from "helpers";
import Loading from "components/Loading";
import { useSelector } from "react-redux";
import { selectUserSelector } from "pages/auth/authSlice";

export default function AnnouncementScreen(props: any) {

    const [keyword, setKeyword] = useState("");
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [currentAnnouncement, setCurrentAnnouncement] = useState<any>({});
    const user = useSelector(selectUserSelector);
    const [pagination, setPagination] = useState<PaginationConfig>({
        limit: PAGINATION.LIMIT_5,
        current: 1,
        total: 0,
        nextToken: null,
        data: [],
    });

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>([]);

    const POPUP_WIDTH = 881;
 
    const handleActionSearch = (value: string) => {
        setPagination({
            ...pagination,
            current: 1
        });
        handleGetAllData();
    }

    useEffect(() => {
        handleGetAllData();
    }, []);

    const handleSearch = () => {
        handleActionSearch(keyword);
    };

    const handleChangeKeyWord = (value: string) => {
        setKeyword(value);
    };

    const onPageChange = (page: number, data: any = []) => {
        handleGetAllData(page);
    };

    const openFile = (item: any) => {
        window.open(item.url, "_blank");
    }

    const handleGetAllData = async (page = 1) => {
        setLoading(true);
        try {
            const { data } = await getListAnnouncement(
                keyword,
                pagination.limit,
                page,
            );
            data?.data?.items.map((item: any) => {
                item.content_html = item.content_html.replace("<img ", "<img style=\"max-width: " + (POPUP_WIDTH - 60) + "px\"");
            });
            setList(data?.data?.items || []);
            setPagination({
                ...pagination,
                current: page,
                total: data?.data?.totalPage * pagination.limit,
                nextToken: data?.data?.nextToken,
                data: data?.data?.items || [],
            });
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }
    
    const showModal = async(item: any) => {
        setCurrentAnnouncement(item);
        setIsModalVisible(true);

        if(!item.is_read) { 
            try {
                const {data} = await updateProviderAnnouncement({
                    announcement_id: item.id
                });
                console.log(data);
                if(data?.code == 200) {
                    setTimeout(() => {
                        item.is_read = true;
                    }, 1000);
                }
            } catch (error) {
                console.log(error)
            }
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Header />
            <Container
                style={{
                    display: 'flex',
                    alignItems: "center",
                    flexDirection: "column",
                    height: (window.innerHeight - 100)
                }}>
                {loading && <Loading/>}
                <SpaceView size={44} />
                <Title>Welcome Back, {user?.full_name} </Title>
                <SpaceView size={11} />
                Discover what's new on the platform
                <SpaceView size={44} />

                <StyledControlContainer>
                    <InputSearch widthInput="643" placeholder="Search for announcement title, date of announcement (dd/MM/yyyy)"
                        handleSearch={handleSearch} handleChange={handleChangeKeyWord}></InputSearch>
                </StyledControlContainer>

                {list.length ? (
                    <div>
                        <Table>
                            <tr>
                                <TableHeader>
                                    Date of Announcement
                                </TableHeader>
                                <TableHeader>
                                    Announcement Title
                                </TableHeader>
                                <TableHeader>
                                    Status
                                </TableHeader>
                            </tr>
                            {list.map((item: any) => (
                                <TableRow style={{ backgroundColor: item.is_read ? '#FAFAFA' : 'white', cursor: 'pointer', fontWeight: item.is_read ? 400 : 500 }}
                                    onClick={() => showModal(item)}>
                                    <td>
                                        {formatDate(item.release_date, 'DD/MM/YYYY', "") }
                                    </td>
                                    <td>
                                        {!item.is_read ? (<img
                                            style={{
                                                width: 44,
                                                height: 21,
                                                marginRight: 10
                                            }}
                                            src={newIcon} alt=""
                                        />) : null}
                                        {item.title}
                                    </td>
                                    <td>
                                        {item.is_read ? (<img
                                            style={{
                                                width: 80,
                                                height: 24,
                                                marginRight: 10
                                            }}
                                            src={readIcon} alt=""
                                        />) : (<img
                                            style={{
                                                width: 75,
                                                height: 24,
                                                marginRight: 10
                                            }}
                                            src={unreadIcon} alt=""
                                        />)}
                                    </td>
                                </TableRow>
                            ))}
                        </Table>

                        <Pagination pagination={pagination} onChange={onPageChange} />

                        <StyledModal
                            wrapClassName={"__upload_file_modal"}
                            title={currentAnnouncement.title}
                            width={POPUP_WIDTH}
                            visible={
                                isModalVisible
                            }
                            maskClosable={false}
                            onCancel={handleCancel}
                            footer={[
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={handleOk}
                                >
                                    I acknowledge
                                </Button>,
                            ]}
                        >
                            <div style={{ fontSize: 14 }}>
                                <div dangerouslySetInnerHTML={{
                                __html: currentAnnouncement.content_html?.replaceAll("\n", "</br>"),
                            }}></div>
                      
                            <div>
                                {(currentAnnouncement.files || []).map((item: any) => (
                                    <div onClick={() => openFile(item)} style={{cursor: "pointer", marginBottom: 10}}>
                                        <img
                                            style={{
                                                width: 24,
                                                height: 24,
                                                marginRight: 5
                                            }}
                                            src={fileIcon} alt=""
                                        />

                                        <span style={{fontSize: 14, fontWeight: 500, color: "#03B4CE", top: 3, position: "relative"}}>{item.name}</span>
                                    </div>
                                ))}
                            </div>
                            </div>
                        </StyledModal>
                    </div>
                ) : (
                    <div
                        className="text-center" style={{marginTop: 50}}>
                        {loading ? (
                            ''
                        ) : (
                            <div className="w-full mt-4 p-8 bg-opacity-20 rounded-xl">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
                    </div>
                )}

            </Container>
        </>
    )
}
