import { Modal } from "antd";
import React from "react";
import styled from "styled-components";

export const Container = styled.div`
    max-width: 1408px;
    margin: 0 auto;
    padding: 24px 36px;
`;

export const Title = styled.h3`
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    color: #494949;
    margin-bottom: 0;
`;

export const Table = styled.table`
    margin-top: 20px;
    font-size: 14px;
    width: 1000px;
    margin-bottom: 20px;
    tr:first-child, tr:last-child {
        border-bottom: solid 1px #E9EEF7;
    }
`;

export const TableHeader = styled.td`
    font-weight: 600;
    line-height: 20px;
    color: #101820;
    font-size: 14px;
    height: 56px;
    padding: 0px 20px;
`;

export const TableRow = styled.tr`
    height: 64px;
    td {
        padding: 0px 20px;
    }
`;

export const StyledModal = styled(Modal)`
    .ant-modal-title {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #121212;
    }
    .ant-modal-header {
      border-radius: 24px 24px 0px 0px;
    }
    .ant-modal-header, .__upload_file_modal .ant-modal-footer {
        border-top: none !important;
    }
    .ant-modal-content {
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.1);
        border-radius: 24px;
    }
    .ant-modal-footer {
        border-top: none !important;
        text-align: center;
        padding-bottom: 33px;
    }
    .ant-modal-footer button {
        width: 143px;
        height: 40px;
        background: #55BED2;
        border: 1px solid #55BED2;
        box-sizing: border-box;
        border-radius: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
    }
`;