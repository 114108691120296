import React from "react";
import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";
import {Tag} from "antd";

const {CheckableTag} = Tag;

export const Container = styled.div`
  max-width: calc(100vw - 160px);
  margin: 0 auto;
  padding: 24px 0;
`

export const FormSessionContainer = styled.div`
  margin: 0 32px 0 74px;
`

export const StepSessionC = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #1B1B1B;
  margin: 0;
`

export const CustomTextArea = styled(TextArea)`
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;
  resize: none;
`

export const CustomTag = styled(CheckableTag)`
  padding: 20px 32px;
  border: 1px solid #BDC3CB;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B1B1B;
  display: flex;
  justify-content: space-between;
  white-space: normal;

  &.ant-tag-checkable-checked {
    background: #EDFBFC;
    border: 1px solid #55BED2;
    box-sizing: border-box;
    border-radius: 24px;
    color: #55BED2;
  }
`

export const ListTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #494949;
`;
export const ListButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;
  background: #55BED2;
  border: 1px solid #55BED2;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  min-width: 280px;
`;
