import React from "react";
import styled from "styled-components";
import {InputNumber} from 'antd';
import * as antd from 'antd';

export const Container = styled.div``;
export const Label = styled.label`
  font-weight: 600;
  font-size: 20px !important;
  line-height: 30px;
  color: #1B1B1B;
  margin-bottom: 16px;
  display: block; 
`;

export const LabelRegular = styled.label`
  font-weight: 400;
  font-size: 20px !important;
  line-height: 30px;
  color: #000000;
  margin: 0 0 8px 0px;
`;

export const LabelRegular1 = styled.label`
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #1B1B1B !important;
`;

export const LabelRegular2 = styled.label`
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1B1B1B !important;
`;

export const LabelRegular3 = styled.label`
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1B1B1B !important;
`;

export const LabelRegular4 = styled.label`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #828282 !important;
`;

export const LabelRegular5 = styled.label`
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 44px !important;
    color: #22313F !important;
`;

export const LabelRegular6 = styled.label`
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #727C8A !important;
`;

export const LabelRegularProps = styled.label`
    font-weight: ${props => props.theme.fontWeight} !important;
    font-size: ${props => props.theme.fontSize} !important;
    color: ${props => props.theme.color} !important;
    line-height: ${props => props.theme.lineHeight} !important;
`;

export const Input = styled(antd.Input)`
  padding: 13px 0px 13px 20px;
  width: 100%;
  height: 50px;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;
  
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B1B1B;

  &:focus, &:hover {
    border: 1px solid #55BED2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
  }
  
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #727C8A;
  }

  &::placeholder {
    color: #727C8A;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #727C8A;
  }

  &::-ms-input-placeholder {
    color: #727C8A;
  }
`;
export const Textarea = styled(antd.Input.TextArea)`
  padding: 13px 0px 13px 20px;
  width: 100%;
  min-height: 115px !important;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 16px;


  &.ant-input-focused {
    border: 1px solid #55BED2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
  }

  &:focus, &:hover {
    border: 1px solid #55BED2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
  }
  
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #727C8A;
  }

  ::placeholder {
    color: #727C8A;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #727C8A;
  }

  ::-ms-input-placeholder {
    color: #727C8A;
  }
`;

export const Number = styled(InputNumber)`
  &.ant-input-number {
    padding: 13px 0px 13px 20px;
    width: 100%;
    height: 50px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;

    &.ant-input-number-focused {
      border: 1px solid #55BED2 !important;
      box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.20) !important;
    }
  }

  & .ant-input-number-input-wrap {
    height: 24px;
  }

  & .ant-input-number-input {
    height: 24px;
    padding-left: 0;
    padding-right: 30px;
    text-align: right;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #101820;

    ::placeholder {
      color: #727C8A;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #727C8A;
    }

    ::-ms-input-placeholder {
      color: #727C8A;
    }
  }
`;
