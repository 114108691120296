import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import authSlice from "../pages/auth/authSlice";
import rootSaga from "./sagas";
import appSlice from "./appSlice";

//MIDDLEWARE
const localStorageMiddleware = ({getState}: any) => {
    return (next: any) => (action: any) => {
        const result = next(action);
        localStorage.setItem('applicationState', JSON.stringify(getState()));
        return result;
    };
};

const reHydrateStore = () => {
    if (localStorage.getItem('applicationState') !== null) {
        const data = JSON.parse(localStorage.getItem('applicationState') || ""); // re-hydrate the store
        data.app.loading = false;
        return data;
    }
};

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({thunk: true}), sagaMiddleware, localStorageMiddleware];


export const store = configureStore({
    reducer: {
        auth: authSlice,
        app: appSlice,
    },
    preloadedState: reHydrateStore(),
    middleware,
    devTools: process.env.NODE_ENV === "development",
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
