import React from "react";
import styled from "styled-components";
import {Space, Table} from "antd";

export const StyledCell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 4px;
`

export const StyledTable = styled(Table)`
    .__styled__row {
        height: 96px;
        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #E9EEF6;
        backdrop-filter: blur(10px);
        border-radius: 16px !important;
        cursor: pointer;
    }
    .__styled__row .ant-table-cell {
        padding: 24px 16px;
        cursor: pointer;
        div {
            cursor: pointer;
            label {
                cursor: pointer;
            }
        }
        &:last-child {
            padding-right: 0;
        }
    }
`

export const StyledStatus = styled.div`
    padding: 2px 10px;
    height: 28px;
    border-radius: 40px;
    width: fit-content;
    background: ${props => props.theme.background};
    font-weight: ${props => props.theme.fontWeight} !important;
    font-size: ${props => props.theme.fontSize} !important;
    color: ${props => props.theme.color};
    line-height: ${props => props.theme.lineHeight} !important;
    &.green {
        color: #7EC820;
        background-color: #EFF8E5;
    }
    &.orange {
        color: #FE6B00;
        background-color: #FFF0E6;
    }
    &.blue {
        color: #01A2FF;
        background-color: #F3F8FC;
    }
    &.yellow {
        color: #F0AD02;
        background-color: #FFF8E6;
    }
    &.purple {
        color: #6A57FC;
        background-color: #F5F4FF;
    }
    &.mintt {
        color: #009B95;
        background-color: #EBFAF9;
    }
    &.pink {
        color: #F3007D;
        background-color: #FFEDFA;
    }
    &.dark-pink {
        color: #9b0080;
        background-color: #ffe5fb;
    }
    &.tur {
        color: #03B4CE;
        background-color: #EDFBFC;
    }
    &.red {
        color: #F04646;
        background-color: #FFEEEE;
    }
`
