import React from "react";
import styled from "styled-components";
import {Button} from "antd";

export const StyledButton = styled(Button)`
    padding: 4px 10px;
    background: #F3F7FC;
    border-radius: 16px;
    height: 48px;
    img {
        width: 11px;
        margin-right: 8px;
    }
`
