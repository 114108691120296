import {RootState} from "../../store";
import {createSlice} from "@reduxjs/toolkit";
import {DoctorTypes} from "../../types/doctor.types";

interface UserData extends DoctorTypes {
  id: string;
  username: string;
  attributes: any;
  accessToken: string;
  idToken: string;
  expiresIn: number;
  refreshToken: string;
  address?: string;
  is_first_login?: boolean
}

export interface AuthState {
  user?: UserData | null;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state, action: { payload: null | Partial<UserData> }) => {
      // when refreshing the tokens, payload will contain just the tokens
      // as opposed to when login it's a full UserData object

      const { payload } = action;

      if (!payload) {
        state.user = null
        return
      }

      let key: keyof UserData;
      const { user } = state;

      for (key in payload) {
        // if action is dispatched with the tokens and the tokens are the same
        // (not frefreshed), do nothing
        if (!user || user[key] !== payload[key]) {
           state.user = { ...user, ...payload as any };
           break;
        }
      }
    },
  },
});

export const {setUserInfo} = authSlice.actions;

export const selectUserSelector = (state: RootState) => state?.auth?.user;

export default authSlice.reducer;
